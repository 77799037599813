* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Roboto", sans-serif;
  background: #223649;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  padding-top: 70px;
}

#root {
  min-width: auto;
  min-height: auto;
}

.main-container > div {
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
  max-width: 100vw;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform, opacity;
  text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
}

/* font styling */

h1 {
  font-size: 50px;
  color: white;
  font-weight: 900;
  font-family: "Courier New", Courier, monospace;
}

h2 {
  font-family: "Inconsolata", monospace;
  color: white;
  padding-left: 205px;
}

li {
  font-family: "Roboto", sans-serif;

  color: white;
}

p {
  color: white;
}

h3 {
  color: white;
}

/* about page styling */
.about-image img {
  max-width: 100vw;
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.html img {
  width: 10%;
  height: 20%;
  padding-left: 10px;
}


.skillspic img {
  width: 15%;
  height: 25%;
}

.pfont h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 750;
  padding-top: 50px;
}

.skillsection {
  padding-right: 30px;
}

.about {
  overflow: hidden;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 10% 40% 25%;
  height: 120vh;
  max-height: 100vh;
}

.about-header {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  align-self: flex-start;
}

.about-image {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  align-self: center;
}

.about-text {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  align-self: center;
  
}

.about-text2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  align-self:flex-start;
  grid-column-start: span 4;
  padding-top: 10px;
  
}



.about-text2 img {
  height: auto;
  width: 100%;
  border-radius: 30%;
  /* box-shadow: 0 0 0 rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  filter: drop-shadow(7px 7px 7px #222222);
  -webkit-filter: drop-shadow(7px 7px 7px #222222);
}

.about-text2 a {
  height: auto;
  width: 5%;
  margin-right: 2%;
}


 
.about-text2 img:hover {
  transform: scale(1.3); /* (130% zoom) */
}

.about-text2-icons {
  display: flex;
  justify-content: center;
}

.about h1 {
  text-align: center;
  font-size: 50px;
}

.about-text p {
  text-align: left;
  font-size: 21px;
}

.about-text2 p {
  text-align: center;
  font-size: 24px;
}
/* portfolio page styling */

.card-text {
  font-size: 18px;
}

.card-deck {
  display: flex;
  flex-direction: row;
}

.card-deck .card {
  background-color: #1c2a36 !important;
  margin-bottom: 30px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.card-img-top {
  width: 100%;
  height: 13vw;
  object-fit: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.card-header {
  text-align: center;
  border: none;
  color: white;
}

.portfolio,
.contact-text h1 {
  text-align: center;
}

.portfolio,
.contact-text p {
  text-align: center;
}

.portfolio,
.contact-text h3 {
  text-align: center;
}

.portfolio-text {
  margin-bottom: 30px;
}

.portfolio-text a {
  color: white;
  text-decoration: underline;
}

.portfolio-buttons .btn {
  margin-bottom: 2%;
}

/* home page styling */

.home-text {
  text-align: center;
  align-self: center;
  font-family: "Courier New", Courier, monospace;
}

.home-text h1 {
  font-size: 70px;
}

.dynamic-text {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 300;
}

.home-container {
  display: grid;
  height: 80vh;
  width: 100%;
  max-height: 100vh;
}

/* contact page styling */

.container {
  text-align: center;
}
/* Skills page */

.skills {
  display: grid;
  grid-template-rows: 20% 80%;
  height: 80vh;
  max-height: 100vh;
  width: 100%;
}

.skills-header {
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: center;
  text-align: left;
}

.skills-card {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  height: 100%;
  width: auto;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.skills-images {
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 33% 33% 33%;
}

.skills-images img {
  height: auto;
  width: 40%;
  margin-right: 7%;
  filter: drop-shadow(7px 7px 7px #222222);
  -webkit-filter: drop-shadow(7px 7px 7px #222222);
}

.skills-individual-images {
  display: grid;
  justify-items: center;
  align-items: center;
  transition: transform 0.2s; /* Animation */
  margin: 0 auto;
}

.skills-individual-images:hover {
  transform: scale(1.3); /* (130% zoom) */
}

/* responsive design */

@media (min-width: 320px) and (max-width: 767.98px) {
  .main-container > div {
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    max-width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }

  h3 {
    font-size: 3vh;
  }

  .card-deck {
    display: inline;
  }

  .card-img-top {
    width: 100%;
    object-fit: cover;
    padding-left: 10px;
    padding-right: 10px;
  }

  .contact-page {
    width: 70%;
  }

  .contact-text p {
    font-size: 12px;
  }

  /* skills page */

  .skills {
    overflow: scroll;
    display: grid;
    grid-template-rows: 20% 80%;
    grid-row-gap: 3%;
    height: auto;
    max-height: 100vh;
    width: 100%;
  }

  .skills-header {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    align-self: center;
    text-align: center;
  }

  .skills-card {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    height: 100%;
    width: auto;
    align-self: center;
    justify-self: center;
    text-align: center;
  }

  .skills-images {
    align-content: center;
    justify-content: center;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    overflow: scroll;
  }

  .skills-images img {
    height: auto;
    width: 50%;
    margin-right: 7%;
  }

  .skills-individual-images {
    display: grid;
    justify-items: center;
    align-items: center;
    margin-top: 7%;
    margin-bottom: 7%;
  }

  /* about page */

  .about {
    overflow: scroll;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 10% 30% auto auto;
    height: 80vh;
    max-height: 100vh;
  }

  .about h2 {
    font-family: "Courier New", Courier, monospace;
  }

  .about-image {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    align-self: center;
    text-align: center;
  }

  .about-image img {
    width: 40%;
    border-radius: 0%;
  }

  .about-text2 img {
    height: auto;
    width: 100%;
    filter: none;
    -webkit-filter: none;
  }

  .about-text2 a {
    height: auto;
    width: 10%;
    margin-right: 2%;
  }

  .about-text {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    align-self: center;
  }

  .about-text2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    align-self: center;
  }

  .about-text p {
    text-align: center;
    font-size: 16px;
  }

  .about-text2 p {
    text-align: center;
    font-size: 16px;
  }

  .home-text h1 {
    font-size: 50px;
  }

  .dynamic-text {
    margin-top: 30px;
    font-weight: lighter;
    font-size: 20px;
  }

  .card-img-top {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-container > div {
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    max-width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }

  h3 {
    font-size: 3vh;
  }

  .card-deck {
    display: inline;
  }

  .card-deck .card {
    margin-bottom: 30px;
  }

  .card-img-top {
    width: 100%;
    object-fit: cover;
    padding-left: 10px;
    padding-right: 10px;
  }

  .portfolio-text {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .contact-page {
    width: 100%;
  }

  .about {
    overflow: scroll;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 15% 35% 35% 15%;
    height: 80vh;
    max-height: 100vh;
  }

  .about-header {
    align-self: center;
  }

  .about-image {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    align-self: center;
    text-align: center;
  }

  .about img {
    max-width: 100vw;
    width: 40%;
  }

  .about-text2 img {
    height: auto;
    width: 100%;
    border-radius: 50%;
    filter: none;
    -webkit-filter: none;
  }

  .about-text2 a {
    height: auto;
    width: 10%;
    margin-right: 2%;
  }

  .about-text p {
    text-align: center;
    font-size: 20px;
  }

  .about-text2 p {
    text-align: center;
    font-size: 20px;
  }

  .about-text {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    align-self: center;
  }

  .about-text2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    align-self: center;
  }

  .skills {
    overflow: scroll;
    display: grid;
    grid-template-rows: 20% 80%;
    height: 80vh;
    max-height: 100vh;
    width: 100%;
  }

  .skills-header {
    grid-column-start: 1;
    grid-column-end: 2;
    align-self: center;
    text-align: center;
  }

  .skills-card {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    height: 100%;
    width: auto;
    align-self: center;
    justify-self: center;
    text-align: center;
  }

  .skills-images {
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 20% 20% 20% 20% 20%;
  }

  .skills-images img {
    height: auto;
    width: 40%;
    margin-right: 7%;
  }

  .skills-individual-images {
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .home-text h1 {
    font-size: 60px;
  }
}
